import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import loginReducer from "../login/reducer";
import cargasReducer from "../cargas/reducer";
import pickingReducer from "../picking/reducer";
import inventoryReducer from "../inventory/reducer";
import configReducer from "../config/reducer";
import efilialReducer from "../efilial/reducer"
import { mainSaga } from "./mainSaga";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const persistConfig = {
  key: "saerp_exp",
  storage,
};
const rootReducer = combineReducers({
  login: loginReducer,
  cargas: cargasReducer,
  picking: pickingReducer,
  inventory: inventoryReducer,
  efilial: efilialReducer,
  config: configReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [logger, sagaMiddleware],
});
export const persistor = persistStore(store);

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;