import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { faArrowLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { QtdInput } from "../../components/Qtdinput";
import { QuaggaBarcodeReader } from "../../components/QuaggaBarcodeReader";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";
import { cargasActions } from "./reducer";

export const ReadBlock: React.FC<{ isCanceling: boolean }> = ({
  isCanceling,
}) => {
  useIsAuth();
  const { ukey } = useParams();

  const navigate = useNavigate();

  const [codigoPallet, setCodigoPallet] = useState("");
  const [qtd, setQtd] = useState(0);
  const [isBarcodeScanOpen, setBarcodeScanOpen] = useState(false);
  const dispatch = useDispatch();
  const dadosPallet = useSelector(
    (state: RootState) => state.cargas.dadosPallet
  );
  const error = useSelector((state: RootState) => state.cargas.error);
  const itens = useSelector((state: RootState) => state.cargas.itens || {});
  const isAddingPallet = useSelector(
    (state: RootState) => state.cargas.isAddingPallet
  );

  useEffect(() => {
    dispatch(cargasActions.clearCheckPallet());
    setBarcodeScanOpen(true);
  }, [dispatch]);

  if (isBarcodeScanOpen) {
    return (
      <Flex
        direction="column"
        w="100vw"
        h="100vh"
        overflowX="visible"
        overflowY="visible"
      >
        <QuaggaBarcodeReader
          isOpened={isBarcodeScanOpen}
          onClose={() => setBarcodeScanOpen(false)}
          onReadBarcode={(code) => {
            setCodigoPallet(code);
            setBarcodeScanOpen(false);
            !isCanceling &&
              dispatch(
                cargasActions.requestCheckPallet({
                  codigoPallet: code,
                  setQtd,
                  itens,
                })
              );
          }}
        />
        <Button
          colorScheme="red"
          position="absolute"
          bottom={0}
          left={0}
          fontSize="2xl"
          width="100vw"
          onClick={() => {
            setBarcodeScanOpen(false);
          }}
        >
          Voltar
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      w="100vw"
      h="100vh"
      overflowX="hidden"
      overflowY="scroll"
      py="1rem"
      px="0.5rem"
    >
      <Flex direction="row">
        <IconButton
          colorScheme="blue"
          aria-label="Voltar"
          mr="0.4rem"
          onClick={() => navigate(`/carga/${ukey}`)}
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
              icon={faArrowLeft}
              title="Voltar"
              color="#eee"
            />
          }
        />
      </Flex>
      <Center my="0.5rem">
        {!isCanceling && <Text fontSize="2xl">Incluir pallet</Text>}
        {isCanceling && <Text fontSize="2xl">Cancelar leitura de pallet</Text>}
      </Center>
      <Center my="0.5rem">
        <Text fontSize="lg">Código do Pallet</Text>
      </Center>

      <Flex my="0.5rem" mx="0.5rem">
        <InputGroup size="md">
          <Input
            value={codigoPallet}
            pattern="\d*"
            type="number"
            onChange={(e) => setCodigoPallet(e.target.value)}
            onBlur={() =>
              !isCanceling &&
              `${codigoPallet}`.length > 3 &&
              dispatch(
                cargasActions.requestCheckPallet({
                  codigoPallet,
                  setQtd,
                  itens,
                })
              )
            }
            placeholder="Código do pallet"
          />
          <InputRightAddon>
            <IconButton
              aria-label="Ler código com a camera"
              icon={<FontAwesomeIcon icon={faCamera} />}
              onClick={() => setBarcodeScanOpen(true)}
            />
          </InputRightAddon>
        </InputGroup>
      </Flex>
      {!isCanceling && (
        <>
          <Center my="0.5rem">
            <Text fontSize="lg">Quantidade Utilizada</Text>
          </Center>
          <Center my="0.5rem">
            <QtdInput
              value={qtd}
              onChange={(qtd) => typeof qtd === "number" && setQtd(qtd)}
            />
          </Center>
          {dadosPallet?.alerts &&
            dadosPallet.alerts.map((alert: any) => (
              <Alert status="warning" flexDirection="column">
                <AlertIcon />
                <AlertTitle>{alert.title}</AlertTitle>
                <AlertDescription>{alert.message}</AlertDescription>
              </Alert>
            ))}
        </>
      )}
      {dadosPallet && !dadosPallet?.k15 && !dadosPallet?.j07 && (
        <Alert status="error" flexDirection="column">
          <AlertIcon />
          <AlertTitle>O Pallet não existe!</AlertTitle>
          <AlertDescription>
            O Pallet {codigoPallet} não existe no banco de dados do sistema!
          </AlertDescription>
        </Alert>
      )}
      {error && (
        <Alert status="error" flexDirection="column">
          <AlertIcon />
          <AlertTitle>Erro na carga!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {!isCanceling && dadosPallet?.diasAvencer > 0 && (
        <Text>{dadosPallet?.diasAvencer} dias para o vencimento.</Text>
      )}
      <Center>
        <Button
          isLoading={isAddingPallet}
          disabled={
            (qtd <= 0 && !isCanceling) || `${codigoPallet}`.trim().length < 3
          }
          onClick={() =>
            dispatch(
              isCanceling
                ? cargasActions.cancelPalletRequest({
                    ukey: ukey || "",
                    codigoPallet,
                    navigate,
                  })
                : cargasActions.addPalletRequest({
                    ukey: ukey || "",
                    qtd,
                    codigoPallet,
                    navigate,
                  })
            )
          }
          colorScheme={isCanceling ? "red" : "blue"}
        >
          {isCanceling ? "Cancelar Leitura" : "Concluir"}
        </Button>
      </Center>
    </Flex>
  );
};
