import { Button, Center, Flex, IconButton, Text } from "@chakra-ui/react";
import { faArrowLeft, faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";

export const Inventory: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();

  const itens = useSelector((state: RootState) => state.inventory.itens);
  return (
    <Flex
      w="100vw"
      h="100vh"
      overflowX="hidden"
      direction="column"
      overflowY="scroll"
    >
      <Flex direction="column" bgColor="blue.200" py="1rem" px="1rem">
        <Flex mx="2rem">
          <IconButton
            colorScheme="blue"
            aria-label="Voltar"
            mr="0.4rem"
            onClick={() => navigate("/")}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faArrowLeft}
                title="Voltar"
                color="#eee"
              />
            }
          />
        </Flex>
        <Center my="0.5rem">
          <Text fontSize="2xl">Inventário</Text>
        </Center>
        <Center my="0.5rem">
          <Text fontSize="xl">Pallets Lidos</Text>
        </Center>
      </Flex>
      <Flex direction="column">
        {itens.map((item, i) => (
          <Flex
            direction="row"
            py="1rem"
            px="1rem"
            justifyContent="space-between"
            key={`${i}`}
          >
            <Text size="lg" as="b">
              {item.codigo}
            </Text>
            <Text size="lg">{item.quantidade}</Text>
          </Flex>
        ))}
        {itens.length === 0 && (
          <Text my="1rem" mx="1rem">
            Nenhum pallet lido ainda!
          </Text>
        )}
      </Flex>
      <Button
        colorScheme="blue"
        position="absolute"
        bottom="0.5rem"
        right="0.5rem"
        borderRadius="1rem"
        onClick={() => navigate(`/inventory/pallet`)}
      >
        <FontAwesomeIcon icon={faBarcode} />
      </Button>
    </Flex>
  );
};
