import { Center, Flex, IconButton, Input, Select } from "@chakra-ui/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";
import { configActions, TPatchSize } from "./reducer";

export const Config: React.FC<{}> = () => {
  useIsAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const width = useSelector((state: RootState) => state.config.width);
  const height = useSelector((state: RootState) => state.config.height);
  const frequency = useSelector((state: RootState) => state.config.frequency);
  const patchSize = useSelector((state: RootState) => state.config.patchSize);

  return (
    <Flex direction="column" w="100vw" h="100vh">
      <Flex direction="row">
        <IconButton
          colorScheme="blue"
          aria-label="Voltar"
          mr="0.4rem"
          onClick={() => navigate(`/`)}
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
              icon={faArrowLeft}
              title="Voltar"
              color="#eee"
            />
          }
        />
      </Flex>
      <Center fontSize="3xl">Configurações</Center>
      <Center fontSize="xl">Largura da camera</Center>
      <Input
        value={width}
        pattern="\d*"
        onChange={(e) =>
          dispatch(configActions.setWidth(parseInt(e.target.value)))
        }
        placeholder="Largura da camera"
      />
      <Center fontSize="xl">Altura da camera</Center>
      <Input
        value={height}
        pattern="\d*"
        onChange={(e) =>
          dispatch(configActions.setHeight(parseInt(e.target.value)))
        }
        placeholder="Altura da camera"
      />
      <Center fontSize="xl">Frequencia de leitura</Center>
      <Select
        value={frequency}
        placeholder="Frequencia de leitura"
        onChange={(e) =>
          dispatch(configActions.setFrequency(parseInt(e.target.value)))
        }
      >
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={8}>8</option>
        <option value={9}>9</option>
        <option value={10}>10</option>
      </Select>
      <Center fontSize="xl">Tamanho do código de barras</Center>
      <Select
        value={patchSize}
        placeholder="Tamanho do código de barras"
        onChange={(e) =>
          dispatch(configActions.setPatchSize(e.target.value as TPatchSize))
        }
      >
        <option value="x-small">PP</option>
        <option value="small">P</option>
        <option value="medium">M</option>
        <option value="large">G</option>
        <option value="x-large">GG</option>
      </Select>
    </Flex>
  );
};
