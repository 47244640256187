import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";

export function useIsAuth() {
  const authToken = useSelector((state: RootState) => state.login.authToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      // Not logged go to login
      navigate("/login");
      return;
    }
  }, [authToken, navigate]);
}
