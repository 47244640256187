import { NavigateFunction } from "react-router-dom";
import { select, takeLatest } from "redux-saga/effects";
import { all } from "redux-saga/effects";
import { inventoryActions } from "./reducer";
import { put } from "redux-saga/effects";
import { call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/mainReducer";
import { apiCall } from "../../utils/apiCall";

const getitens = (state: RootState) => state.inventory.itens;

function* checkPalletWorker({ payload }: PayloadAction<string>) {
  try {
    const itens: Record<string, any>[] = yield select(getitens);
    if (itens.findIndex((item) => item.codigo === payload) !== -1) {
      yield put(
        inventoryActions.checkPalletError(`O pallet ${payload} já foi lido`)
      );
      return;
    }

    const res: { data: Record<string, any> } = yield call(apiCall, {
      url: `/apiCargas/pallet/${payload}?inventario=1`,
      method: "GET",
    });

    yield put(inventoryActions.checkPalletSuccess(res.data));
  } catch (error: any) {
    yield put(
      inventoryActions.checkPalletError(
        `Erro ao verificar pallet online: ${error?.response?.data ? error.response.data.error : JSON.stringify(error)
        }`
      )
    );
  }
}

function* sendInventarioWorker({
  payload,
}: PayloadAction<{
  codigoPallet: string;
  qtd: number;
  navigate: NavigateFunction;
}>) {
  try {
    yield call(apiCall, {
      url: `/apiCargas/inventario/${payload.codigoPallet}`,
      method: "POST",
      data: {
        qtd: payload.qtd,
      },
    });
    yield put(
      inventoryActions.addInventoryItem({
        codigo: payload.codigoPallet,
        quantidade: payload.qtd,
      })
    );
    yield put(inventoryActions.sendInventarioSuccess());
    payload.navigate(`/inventory`);
  } catch (error: any) {
    yield put(
      inventoryActions.sendInventarioError(
        `Erro ao salvar inventário: ${error?.response?.data ? error.response.data : JSON.stringify(error)
        }`
      )
    );
  }
}

export function* inventorySaga() {
  yield all([
    takeLatest("inventory/requestCheckPallet", checkPalletWorker),
    takeLatest("inventory/requestSendInventario", sendInventarioWorker),
  ]);
}