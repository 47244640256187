import { PayloadAction } from "@reduxjs/toolkit";
import { apiCall } from "../../utils/apiCall";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { loginActions } from "./reducer";
import crypto from "crypto-js";

function* empresasWorker() {
  try {
    const res: AxiosResponse<{ data: Record<string, any>[] }> = yield call(
      apiCall,
      {
        url: "/login/empresas",
        method: "GET",
      }
    );
    console.log("empresas retornadas", res.data);
    yield put(loginActions.empresasSuccess(res.data.data));
  } catch (error) {
    yield put(
      loginActions.empresasError(
        `Erro ao carregar empresas: ${JSON.stringify(error)}`
      )
    );
  }
}

function* loginWorker({
  payload,
}: PayloadAction<{ usuario: string; senha: string; empresa: string }>) {
  try {
    const dataToSend = { ...payload };
    dataToSend.senha = crypto.SHA512(payload.senha).toString(crypto.enc.Base64);
    console.log("sending data", dataToSend);
    const res: AxiosResponse<any> = yield call(apiCall, {
      url: "/login/auth",
      method: "POST",
      data: {
        ...dataToSend,
      },
    });
    if (res.data?.error) {
      yield put(loginActions.loginError(res.data?.error));
    } else {
      yield put(loginActions.loginSuccess(res.data.authToken));
    }
  } catch (error) {
    yield put(
      loginActions.loginError(`Erro ao logar: ${JSON.stringify(error)}`)
    );
  }
}

export function* loginSaga() {
  yield all([
    takeLatest("login/empresasRequest", empresasWorker),
    takeLatest("login/loginRequest", loginWorker),
  ]);
}
