import {
  Text,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import { faArrowLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { QuaggaBarcodeReader } from "../../components/QuaggaBarcodeReader";
import { RootState } from "../app/mainReducer";
import { efilialActions } from "./reducer";

export const ReadBlockEfilial: React.FC<{}> = () => {
  const [isBarcodeScanOpen, setBarcodeScanOpen] = useState(false);
  const [codigoPallet, setCodigoPallet] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dadosPallet = useSelector(
    (state: RootState) => state.efilial.dadosPallet
  );
  const isCheckingPallet = useSelector(
    (state: RootState) => state.efilial.isCheckingPallet
  );
  const isAddingPallet = useSelector(
    (state: RootState) => state.efilial.isAddingPallet
  );
  const error = useSelector((state: RootState) => state.efilial.error);

  useEffect(() => {
    dispatch(efilialActions.clearCheckPallet());
    setBarcodeScanOpen(true);
  }, [dispatch]);

  const confirmReading = () => {
    // Enviar inventario que ja esta correto
    dispatch(
      efilialActions.requestSendInventario({
        codigoPallet,
        qtd: dadosPallet?.k15?.K15_004_N,
        navigate,
      })
    );
  };

  if (isBarcodeScanOpen) {
    return (
      <Flex
        direction="column"
        w="100vw"
        h="85vh"
        overflowX="hidden"
        overflowY="hidden"
      >
        <QuaggaBarcodeReader
          isOpened={isBarcodeScanOpen}
          onClose={() => setBarcodeScanOpen(false)}
          onReadBarcode={(code) => {
            setCodigoPallet(code);
            setBarcodeScanOpen(false);
            dispatch(efilialActions.requestCheckPallet(code));
          }}
        />
        <Button
          colorScheme="red"
          position="absolute"
          bottom={0}
          left={0}
          width="100vw"
          onClick={() => {
            setBarcodeScanOpen(false);
          }}
        >
          Voltar
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      overflowX="hidden"
      overflowY="scroll"
      direction="column"
      py="1rem"
      px="0.5rem"
      w="100vw"
      h="100vh"
    >
      <Flex direction="row">
        <IconButton
          colorScheme="blue"
          aria-label="Voltar"
          mr="0.4rem"
          onClick={() => navigate(`/inventory`)}
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
              icon={faArrowLeft}
              title="Voltar"
              color="#eee"
            />
          }
        />
      </Flex>
      <Center my="0.5rem">
        <Text fontSize="2xl">Incluir pallet</Text>
      </Center>
      <Center my="0.5rem">
        <Text fontSize="lg">Código do Pallet</Text>
      </Center>

      <Flex my="0.5rem" mx="0.5rem">
        <InputGroup size="md">
          <Input
            value={codigoPallet}
            onChange={(e) => setCodigoPallet(e.target.value)}
            onBlur={() =>
              codigoPallet.length > 3 &&
              dispatch(efilialActions.requestCheckPallet(codigoPallet))
            }
            placeholder="Código do pallet"
          />
          <InputRightAddon>
            <IconButton
              aria-label="Ler código com a camera"
              icon={<FontAwesomeIcon icon={faCamera} />}
              onClick={() => setBarcodeScanOpen(true)}
            />
          </InputRightAddon>
        </InputGroup>
      </Flex>
      {dadosPallet && dadosPallet.d04 && (
        <>
          <Center>
            <Text fontSize="lg">
              Item: {dadosPallet.d04.D04_001_C} - {dadosPallet.d04.D04_002_C}
            </Text>
          </Center>
          <Center>
            <Text fontSize="lg">
              Quantidade:
              {dadosPallet?.k15?.K15_004_N}
            </Text>
          </Center>
        </>
      )}
      {dadosPallet && !dadosPallet?.k15 && !dadosPallet?.j07 && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>O Pallet não existe!</AlertTitle>
          <AlertDescription>
            O Pallet {codigoPallet} não existe no banco de dados do sistema!
          </AlertDescription>
        </Alert>
      )}
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Erro na inventario!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {dadosPallet?.diasAvencer > 0 && (
        <Text>{dadosPallet?.diasAvencer} dias para o vencimento.</Text>
      )}
      <Center>
        <Button
          isLoading={isAddingPallet || isCheckingPallet}
          disabled={
            `${codigoPallet}`.trim().length < 3
          }
          onClick={() => confirmReading()}
          colorScheme="blue"
        >
          Confirmar
        </Button>
      </Center>
    </Flex>
  );
};