import axios from "axios";
import { AxiosRequestConfig } from "axios";
import { store } from "../modules/app/mainReducer";
import getEnv from "./getEnv";

export const APIURL = getEnv("REACT_APP_API_HOST");

export const getToken = () => store.getState().login.authToken;

export const apiCall = (config: AxiosRequestConfig) =>
  axios({
    ...config,
    baseURL: APIURL,
    headers: {
      "x-access-token": `${getToken()}`,
    },
  });
