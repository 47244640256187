import { pickingActions } from "./reducer";
import { all, put, takeLatest } from "redux-saga/effects";
import { call } from "redux-saga/effects";
import { apiCall } from "../../utils/apiCall";
import _ from "underscore";

function* reportWorker() {
  try {
    const res: { data: Record<string, any>; error: string } = yield call(
      apiCall,
      {
        url: "/apiCargas/picking",
      }
    );
    if (res.error) {
      yield put(pickingActions.reportError(res.error));
    } else {
      const itens = _.sortBy(
        res.data,
        (item: any) => `${item.pallets}${item.D04_001_C}`
      );
      yield put(pickingActions.reportSuccess(itens));
    }
  } catch (error: any) {
    if (error?.response?.data) {
      yield put(
        pickingActions.reportError(
          `Erro ao carregar relatório: ${error.response.data}`
        )
      );
    } else {
      yield put(
        pickingActions.reportError(
          `Erro ao carregar relatório: ${JSON.stringify(error)}`
        )
      );
    }
  }
}

export default function* pickingSaga() {
  yield all([takeLatest("picking/requestReport", reportWorker)]);
}
