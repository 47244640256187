import { Button, Text } from "@chakra-ui/react";
import { formatPlate } from "../../utils/formatPlate";
import { getTipoCarga } from "../../utils/getTipoCarga";
import { extractItens } from "./extractItens";
import _ from "underscore";

export const ShippingItem: React.FC<{
  carga: Record<string, any>;
  onClick: () => void;
}> = ({ carga, onClick }) => {
  const plateFormated = carga.ff08
    ? formatPlate(carga.ff08.FF08_001_C.trim())
    : "SEM PLACA";
  const jaCarregando = !!carga.k19;
  const itens = extractItens(carga);
  const qtdFaltando = _.reduce(itens, (a, n) => a + n.qtd - n.read, 0);
  const cor = !jaCarregando ? "blue" : qtdFaltando > 0 ? "red" : "green";

  return (
    <Button
      py="0.6rem"
      px="0.3rem"
      border="1px"
      variant="solid"
      colorScheme={cor}
      display="flex"
      flexDirection="column"
      textAlign="left"
      alignItems="start"
      h="auto"
      onClick={onClick}
      // borderStyle="solid"
      // borderColor="blue.800"
      // borderRadius="5px"
      // bgColor="blue.100"
      my="0.1rem"
    >
      <Text as="b">{plateFormated} - CARGA</Text>
      <Text>Tipo carga: {getTipoCarga(carga)}</Text>
      <Text>Motorista: {carga.a03?.A03_003_C || "Não identificado"}</Text>
      <Text>Peso Tara: {carga.N01_007_N}</Text>
    </Button>
  );
};
