import { Button, ButtonProps, CircularProgress } from "@chakra-ui/react";

export const LoaderButton: React.FC<{ isLoading?: boolean } & ButtonProps> = ({
  isLoading,
  disabled,
  children,
  ...props
}) => {
  return (
    <Button
      disabled={isLoading || disabled}
      opacity={isLoading || disabled ? "0.5" : "1"}
      {...props}
    >
      {isLoading && <CircularProgress isIndeterminate color="white" />}
      {children}
    </Button>
  );
};
