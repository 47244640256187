import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../app/mainReducer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loginActions } from "./reducer";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { LoaderButton } from "../../components/LoaderButton";
import logo from "../../SAERP.png";
import getEnv from "../../utils/getEnv";

const empresaPadrao = getEnv("REACT_APP_CIA_UKEY")

export const Login: React.FC<{}> = () => {
  const authToken = useSelector((state: RootState) => state.login.authToken);
  const empresas = useSelector((state: RootState) => state.login.empresas);
  const isRequestingEmpresas = useSelector(
    (state: RootState) => state.login.isRequestingEmpresas
  );
  const isRequestingLogin = useSelector(
    (state: RootState) => state.login.isRequestingLogin
  );
  const error = useSelector((state: RootState) => state.login.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ usuario: string; senha: string; empresa: string }>();

  useEffect(() => {
    if (!!authToken) {
      // Not logged go to login
      navigate("/");
      return;
    }

    dispatch(loginActions.empresasRequest());
  }, [authToken, navigate, dispatch]);
  if (isRequestingEmpresas) {
    return (
      <Container width="100vw" height="100vh" centerContent>
        <CircularProgress isIndeterminate color="teal" />
      </Container>
    );
  }

  return (
    <Container width="100vw" height="100vh" centerContent pt="2rem">
      <Box backgroundColor="teal.100" borderRadius="5" py="2rem" px="1rem">
        <Image src={logo} />
        <Heading mx="auto" textAlign="center">
          Expedição
        </Heading>
        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Erro no login!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <form
          onSubmit={handleSubmit((data) =>
            dispatch(loginActions.loginRequest(data))
          )}
        >
          <FormControl isInvalid={!!errors.empresa}>
            <FormLabel htmlFor="empresa">Empresa</FormLabel>
            <Select
              id="empresa"
              placeholder="Empresa"
              {...register("empresa", {
                required: "Selecione a empresa",
                value: !!empresaPadrao ? empresaPadrao : (empresas[0] && empresas[0].UKEY),
              })}
            >
              {empresas.map((empresa) => (
                <option key={empresa.UKEY} value={empresa.UKEY}>
                  {empresa.descricao}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.empresa && errors.empresa.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.usuario}>
            <FormLabel htmlFor="usuario">Usuario</FormLabel>
            <Input
              id="usuario"
              placeholder="Usuario"
              {...register("usuario", { required: "Preencha o usuario" })}
            />
            <FormErrorMessage>
              {errors.usuario && errors.usuario.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.senha}>
            <FormLabel htmlFor="senha">Senha</FormLabel>
            <Input
              id="senha"
              placeholder="Senha"
              type="password"
              {...register("senha", { required: "Preencha a senha" })}
            />
            <FormErrorMessage>
              {errors.senha && errors.senha.message}
            </FormErrorMessage>
          </FormControl>
          <LoaderButton
            isLoading={isRequestingLogin}
            colorScheme="teal"
            size="lg"
            mt="1rem"
            float="right"
            type="submit"
            disabled={!!errors.empresa || !!errors.senha || !!errors.usuario}
          >
            Entrar
          </LoaderButton>
        </form>
      </Box>
    </Container>
  );
};
