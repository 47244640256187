import { NavigateFunction } from "react-router-dom";
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  itens: Record<string, any>[];
  error: string;
  isAddingPallet: boolean;
  isCheckingPallet: boolean;
  dadosPallet?: Record<string, any>;
};

const initialState: TInitialState = {
  itens: [],
  error: "",
  isAddingPallet: false,
  isCheckingPallet: false,
};

const reducer = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    startInventory(state) {
      state.itens = [];
      state.error = "";
      state.isAddingPallet = false;
    },
    addInventoryItem(state, { payload }: PayloadAction<Record<string, any>>) {
      state.itens = [...state.itens, payload];
    },
    requestCheckPallet(state, _: PayloadAction<string>) {
      state.isCheckingPallet = true;
      state.error = "";
      state.dadosPallet = undefined;
    },
    checkPalletSuccess(state, { payload }: PayloadAction<Record<string, any>>) {
      state.isCheckingPallet = false;
      state.dadosPallet = payload;
    },
    checkPalletError(state, { payload }: PayloadAction<string>) {
      state.isCheckingPallet = false;
      state.error = payload;
    },
    clearCheckPallet(state) {
      state.dadosPallet = undefined;
      state.isCheckingPallet = false;
    },
    requestSendInventario(
      state,
      _: PayloadAction<{
        codigoPallet: string;
        qtd: number;
        navigate: NavigateFunction;
      }>
    ) {
      state.isAddingPallet = true;
      state.error = "";
    },
    sendInventarioSuccess(state) {
      state.isAddingPallet = false;
    },
    sendInventarioError(state, { payload }: PayloadAction<string>) {
      state.isAddingPallet = false;
      state.error = payload;
    },
  },
});

export const inventoryActions = reducer.actions;

export default reducer.reducer;
