import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  authToken: string;
  usuario: string;
  cia: string;
  isRequestingLogin: boolean;
  isRequestingEmpresas: boolean;
  empresas: Record<string, any>[];
  error: string;
};

const initialState: TInitialState = {
  authToken: "",
  usuario: "",
  cia: "",
  isRequestingEmpresas: false,
  isRequestingLogin: false,
  empresas: [],
  error: "",
};

const reducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginRequest(
      state,
      {
        payload,
      }: PayloadAction<{ usuario: string; senha: string; empresa: string }>
    ) {
      state.isRequestingLogin = true;
      state.error = "";
      state.usuario = payload.usuario;
    },
    loginSuccess(state, { payload }: PayloadAction<string>) {
      state.isRequestingLogin = false;
      state.authToken = payload;
    },
    loginError(state, { payload }: PayloadAction<string>) {
      state.isRequestingLogin = false;
      state.error = payload;
    },
    empresasRequest(state) {
      state.isRequestingEmpresas = true;
      state.error = "";
    },
    empresasSuccess(state, { payload }: PayloadAction<Record<string, any>[]>) {
      state.isRequestingEmpresas = false;
      state.empresas = payload;
    },
    empresasError(state, { payload }: PayloadAction<string>) {
      state.isRequestingEmpresas = false;
      state.error = payload;
    },
    logout(state) {
      state.usuario = "";
      state.authToken = "";
      state.cia = "";
      state.error = "";
    },
  },
});

export const loginActions = reducer.actions;

export default reducer.reducer;
