import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  CircularProgress,
  CloseButton,
  Flex,
  IconButton,
  Select,
  Text,
} from "@chakra-ui/react";
import {
  faArrowLeft,
  faBan,
  faBarcode,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumber } from "../../utils/formatNumber";
import { formatPlate } from "../../utils/formatPlate";
import { getTipoCarga } from "../../utils/getTipoCarga";
import { RootState } from "../app/mainReducer";
import { cargasActions } from "./reducer";
import _ from "underscore";
import { Item } from "./item";
import { LoaderButton } from "../../components/LoaderButton";
import { Ripped } from "./ripped";

export const Carga: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { ukey } = useParams();
  const carga = useSelector((state: RootState) => state.cargas.carga || {});
  const itens = useSelector((state: RootState) => state.cargas.itens || {});
  const error = useSelector((state: RootState) => state.cargas.error);
  const isCargaLoading = useSelector(
    (state: RootState) => state.cargas.isLoadingCarga
  );
  const isRipping = useSelector((state: RootState) => state.cargas.isRipping);
  const carguistas = useSelector(
    (state: RootState) => state.cargas.carguistas || []
  );
  const plateFormated = carga.ff08
    ? formatPlate(carga.ff08.FF08_001_C.trim())
    : "SEM PLACA";
  const [carguista, setCarguista] = useState("");
  const isFinishing = useSelector(
    (state: RootState) => state.cargas.isFinishing
  );

  const [rippedItem, setRippedItem] = useState<Record<string, any> | undefined>(
    undefined
  );

  const navigate = useNavigate();

  const pesoTotal =
    carga.j14s &&
    carga.j14s.reduce((_: any, v: any) => {
      if (v.j15s) {
        let pesoj15 = v.j15s.reduce((_: any, j15: any) => {
          if (j15.j07) {
            let pesoJ08 = j15.j07.j08s.reduce((_: any, j08: any) => {
              if (j08.d04) {
                return _ + j08.J08_003_N * j08.d04.D04_022_B;
              }
              return _;
            }, 0);
            return _ + pesoJ08;
          }
          return _;
        }, 0);
        return _ + pesoj15;
      }
      return _;
    }, 0);

  useEffect(() => {
    ukey && dispatch(cargasActions.requestCarga(ukey));
    dispatch(cargasActions.carguistaRequest());
    setCarguista("");
  }, [dispatch, ukey]);

  if (isCargaLoading || isRipping) {
    return (
      <Flex
        w="100vw"
        h="100vh"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress isIndeterminate={true} />
      </Flex>
    );
  }
  if (rippedItem) {
    return (
      <Ripped
        item={rippedItem}
        carga={ukey || ""}
        onClose={() => setRippedItem(undefined)}
      />
    );
  }

  const voltarClick = () => {
    window.confirm(
      "Tem certeza que deseja parar o carregamento desta carga?"
    ) && navigate("/");
  };

  const qtdFaltando = _.reduce(itens, (a, n) => a + n.qtd - n.read, 0);
  console.log("itens", itens);

  return (
    <Flex direction="column" w="100vw" h="100vh">
      <Flex direction="column" bgColor="blue.200">
        <Center
          justifyContent="space-between"
          alignSelf="center"
          px="1rem"
          py="0.5rem"
          maxW="300px"
        >
          <IconButton
            colorScheme="blue"
            aria-label="Voltar"
            mr="0.4rem"
            onClick={voltarClick}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faArrowLeft}
                title="Voltar"
                color="#eee"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Recarregar"
            mr="0.4rem"
            onClick={() => ukey && dispatch(cargasActions.requestCarga(ukey))}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faRefresh}
                title="Recarregar"
                color="#eee"
              />
            }
          />
        </Center>
        <Center my="0.3rem">
          <Text as="b">{plateFormated}</Text>
        </Center>
        <Center my="0.3rem">
          <Text as="b">Tipo carga: {getTipoCarga(carga)}</Text>
        </Center>
        <Center my="0.3rem">
          <Text as="b">
            Motorista: {carga.a03?.A03_003_C || "Não identificado"}
          </Text>
        </Center>
        <Center my="0.3rem">
          <Text as="b">Peso carga: {formatNumber(pesoTotal, 2, true)}</Text>
        </Center>
        {qtdFaltando <= 0 && (
          <>
            <Center mx="1rem" mb="0.3rem">
              <Select
                value={carguista}
                onChange={(e) => setCarguista(e.target.value)}
                placeholder="Selecione um carguista"
              >
                {carguistas &&
                  carguistas.map((c) => (
                    <option value={c.UKEY}>{c.A46_002_C}</option>
                  ))}
              </Select>
            </Center>
            <LoaderButton
              onClick={() =>
                dispatch(
                  cargasActions.concluirRequest({
                    UKEY: carga.UKEY,
                    operador: carguista,
                    navigate,
                  })
                )
              }
              isLoading={isFinishing}
              disabled={!carguista}
              colorScheme="blue"
              mx="1rem"
              mb="0.3rem"
            >
              Concluir
            </LoaderButton>
          </>
        )}
      </Flex>
      <Flex direction="column" overflowY="scroll">
        {error && (
          <Alert status="error" minH="3rem">
            <AlertIcon />
            <AlertTitle>Erro!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={() => dispatch(cargasActions.clearError())}
            />
          </Alert>
        )}
        {_.map(itens, (item: any, i: number) => (
          <Item
            item={item}
            key={`${i}`}
            onRipped={(item) => setRippedItem(item)}
          />
        ))}
      </Flex>
      <Button
        colorScheme="red"
        position="absolute"
        bottom="0.5rem"
        left="0.5rem"
        borderRadius="1rem"
        onClick={() => navigate(`/pallet/cancel/${ukey}`)}
      >
        <FontAwesomeIcon icon={faBan} />
      </Button>
      <Button
        colorScheme="blue"
        position="absolute"
        bottom="0.5rem"
        right="0.5rem"
        borderRadius="1rem"
        onClick={() => navigate(`/pallet/${ukey}`)}
      >
        <FontAwesomeIcon icon={faBarcode} />
      </Button>
    </Flex>
  );
};
