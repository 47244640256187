import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  isLoading: boolean;
  itens: Record<string, any>[];
  error: string;
};

const initialState: TInitialState = {
  isLoading: false,
  itens: [],
  error: "",
};

const reducer = createSlice({
  name: "picking",
  initialState,
  reducers: {
    requestReport(state) {
      state.isLoading = true;
      state.error = "";
      state.itens = [];
    },
    reportSuccess(state, { payload }: PayloadAction<Record<string, any>[]>) {
      state.isLoading = false;
      state.itens = payload;
    },
    reportError(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const pickingActions = reducer.actions;

export default reducer.reducer;
