import { Button, Center, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QtdInput } from "../../components/Qtdinput";
import { RootState } from "../app/mainReducer";
import { cargasActions } from "./reducer";

export const Ripped: React.FC<{
  item: Record<string, any>;
  carga: string;
  onClose: () => void;
}> = ({ item, onClose, carga }) => {
  const [qtd, setQtd] = useState(1);
  const isRipping = useSelector((state: RootState) => state.cargas.isRipping);
  const dispatch = useDispatch();

  return (
    <Flex w="100vw" h="100vh" direction="column" py="1rem" px="0.5rem">
      <Text fontSize="lg" as="b">
        Informar rasgado do item
      </Text>
      <Text fontSize="md" as="b">
        {item.D04_001_C} - {item.D04_002_C}
      </Text>
      <Center my="0.5rem">
        <Text fontSize="lg">Quantidade Rasgada</Text>
      </Center>
      <Center my="0.5rem">
        <QtdInput
          value={qtd}
          onChange={(qtd) => typeof qtd === "number" && setQtd(qtd)}
        />
      </Center>
      <Center my="0.5rem">
        <Button
          isLoading={isRipping}
          onClick={() => {
            dispatch(
              cargasActions.rippedRequest({ carga, item: item.UKEY, qtd })
            );
            onClose();
          }}
          colorScheme="blue"
        >
          Concluir
        </Button>
      </Center>
      <Center my="0.5rem">
        <Button colorScheme="red" onClick={onClose}>
          Voltar
        </Button>
      </Center>
    </Flex>
  );
};
