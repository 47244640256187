import {
  Text,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react";
import { faArrowLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { QtdInput } from "../../components/Qtdinput";
import { QuaggaBarcodeReader } from "../../components/QuaggaBarcodeReader";
import { RootState } from "../app/mainReducer";
import { inventoryActions } from "./reducer";

export const ReadBlockInventory: React.FC<{}> = () => {
  const [isBarcodeScanOpen, setBarcodeScanOpen] = useState(false);
  const [isPenddingConfirm, setPendingConfirm] = useState(false);
  const [codigoPallet, setCodigoPallet] = useState("");
  const [qtd, setQtd] = useState(0);
  const [lastro, setLastro] = useState(0);
  const [altura, setAltura] = useState(0);
  const [extra, setExtra] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dadosPallet = useSelector(
    (state: RootState) => state.inventory.dadosPallet
  );
  const isCheckingPallet = useSelector(
    (state: RootState) => state.inventory.isCheckingPallet
  );
  const isAddingPallet = useSelector(
    (state: RootState) => state.inventory.isAddingPallet
  );
  const error = useSelector((state: RootState) => state.inventory.error);

  useEffect(() => {
    dispatch(inventoryActions.clearCheckPallet());
    setBarcodeScanOpen(true);
  }, [dispatch]);

  const confirmReading = () => {
    const qtdInformada = lastro * altura + extra;
    if (
      dadosPallet?.k15?.K15_004_N - qtdInformada !== 0 &&
      dadosPallet?.k15.K15_004_N !== 0
    ) {
      setQtd(dadosPallet?.k15?.K15_004_N || 0);
      setPendingConfirm(true);
    } else {
      // Enviar inventario que ja esta correto
      dispatch(
        inventoryActions.requestSendInventario({
          codigoPallet,
          qtd: qtdInformada,
          navigate,
        })
      );
    }
  };

  if (isBarcodeScanOpen) {
    return (
      <Flex
        direction="column"
        w="100vw"
        h="85vh"
        overflowX="hidden"
        overflowY="hidden"
      >
        <QuaggaBarcodeReader
          isOpened={isBarcodeScanOpen}
          onClose={() => setBarcodeScanOpen(false)}
          onReadBarcode={(code) => {
            setCodigoPallet(code);
            setBarcodeScanOpen(false);
            dispatch(inventoryActions.requestCheckPallet(code));
          }}
        />
        <Button
          colorScheme="red"
          position="absolute"
          bottom={0}
          left={0}
          width="100vw"
          onClick={() => {
            setBarcodeScanOpen(false);
          }}
        >
          Voltar
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      overflowX="hidden"
      overflowY="scroll"
      direction="column"
      py="1rem"
      px="0.5rem"
      w="100vw"
      h="100vh"
    >
      <Flex direction="row">
        <IconButton
          colorScheme="blue"
          aria-label="Voltar"
          mr="0.4rem"
          onClick={() => navigate(`/inventory`)}
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
              icon={faArrowLeft}
              title="Voltar"
              color="#eee"
            />
          }
        />
      </Flex>
      <Center my="0.5rem">
        <Text fontSize="2xl">Incluir pallet</Text>
      </Center>
      <Center my="0.5rem">
        <Text fontSize="lg">Código do Pallet</Text>
      </Center>

      <Flex my="0.5rem" mx="0.5rem">
        <InputGroup size="md">
          <Input
            value={codigoPallet}
            onChange={(e) => setCodigoPallet(e.target.value)}
            onBlur={() =>
              codigoPallet.length > 3 &&
              dispatch(inventoryActions.requestCheckPallet(codigoPallet))
            }
            disabled={isPenddingConfirm}
            placeholder="Código do pallet"
          />
          {!isPenddingConfirm && (
            <InputRightAddon>
              <IconButton
                aria-label="Ler código com a camera"
                icon={<FontAwesomeIcon icon={faCamera} />}
                onClick={() => setBarcodeScanOpen(true)}
              />
            </InputRightAddon>
          )}
        </InputGroup>
      </Flex>
      {!isPenddingConfirm && (
        <>
          <Center my="0.5rem">
            <Text fontSize="lg">Lastro</Text>
          </Center>
          <Center my="0.5rem">
            <QtdInput
              value={lastro}
              onChange={(lastro) =>
                typeof lastro === "number" && setLastro(lastro)
              }
            />
          </Center>
          <Center my="0.5rem">
            <Text fontSize="lg">Altura</Text>
          </Center>
          <Center my="0.5rem">
            <QtdInput
              value={altura}
              onChange={(altura) =>
                typeof altura === "number" && setAltura(altura)
              }
            />
          </Center>
          <Center my="0.5rem">
            <Text fontSize="lg">Extra</Text>
          </Center>
          <Center my="0.5rem">
            <QtdInput
              value={extra}
              onChange={(extra) => typeof extra === "number" && setExtra(extra)}
            />
          </Center>
          <Center my="0.5rem">
            <Text fontSize="lg">Total: {lastro * altura + extra}</Text>
          </Center>
        </>
      )}
      {dadosPallet && dadosPallet.d04 && (
        <Center>
          <Text fontSize="lg">
            Item: {dadosPallet.d04.D04_001_C} - {dadosPallet.d04.D04_002_C}
          </Text>
        </Center>
      )}
      {dadosPallet && !dadosPallet?.k15 && !dadosPallet?.j07 && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>O Pallet não existe!</AlertTitle>
          <AlertDescription>
            O Pallet {codigoPallet} não existe no banco de dados do sistema!
          </AlertDescription>
        </Alert>
      )}
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Erro na inventario!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {dadosPallet?.diasAvencer > 0 && (
        <Text>{dadosPallet?.diasAvencer} dias para o vencimento.</Text>
      )}
      {isPenddingConfirm && (
        <>
          <Text>
            A quantidade informada é diferente da quantidade atual no Pallet
          </Text>
          <Text>Selecione qual quantidade utilizar</Text>
          <Button
            my="1rem"
            onClick={() =>
              dispatch(
                inventoryActions.requestSendInventario({
                  codigoPallet,
                  qtd: lastro * altura + extra,
                  navigate,
                })
              )
            }
            colorScheme="green"
          >
            O que eu informei
          </Button>
          <Button
            my="1rem"
            onClick={() =>
              dispatch(
                inventoryActions.requestSendInventario({
                  codigoPallet,
                  qtd,
                  navigate,
                })
              )
            }
            colorScheme="blue"
          >
            A quantidade {qtd} que esta no sistema
          </Button>
        </>
      )}
      {!isPenddingConfirm && (
        <Center>
          <Button
            isLoading={isAddingPallet || isCheckingPallet}
            disabled={
              lastro <= 0 || altura <= 0 || `${codigoPallet}`.trim().length < 3
            }
            onClick={() => confirmReading()}
            colorScheme="blue"
          >
            Confirmar
          </Button>
        </Center>
      )}
    </Flex>
  );
};
