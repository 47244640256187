export const getTipoCarga = (carga: Record<string, any>) => {
  if (!carga.j14s || carga.j14s.length < 1) {
    return "Indeterminada";
  }
  switch (carga.j14s[0].J14_018_N) {
    case 1:
      return "Cadastrada";
    case 2:
      return "Batida";
    case 3:
      return "Paletizada";
    case 4:
      return "Big Bag";
    case 5:
      return "Granel";
    case 6:
      return "Farelo";
    default:
      return "Indeterminada";
  }
};
