import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TPatchSize = "x-small" | "small" | "medium" | "large" | "x-large";

type TInitialState = {
  width: number;
  height: number;
  frequency: number;
  patchSize: TPatchSize;
};

const initialState: TInitialState = {
  width: 1920,
  height: 1080,
  frequency: 3,
  patchSize: "small",
};

const reducer = createSlice({
  name: "config",
  initialState,
  reducers: {
    setWidth(state, { payload }: PayloadAction<number>) {
      state.width = payload;
    },
    setHeight(state, { payload }: PayloadAction<number>) {
      state.height = payload;
    },
    setFrequency(state, { payload }: PayloadAction<number>) {
      state.frequency = payload;
    },
    setPatchSize(state, { payload }: PayloadAction<TPatchSize>) {
      state.patchSize = payload;
    },
  },
});

export const configActions = reducer.actions;

export default reducer.reducer;
