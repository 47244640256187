import { extractItens } from "./extractItens";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";

type TInitialState = {
  isLoading: boolean;
  cargas: Record<string, any>[];
  error: string;
  isLoadingCarga: boolean;
  carga?: Record<string, any>;
  itens?: Record<string, any>;
  carguistas?: Record<string, any>[];
  isCarguistaLoading?: boolean;
  isFinishing?: boolean;
  isCheckingPallet?: boolean;
  dadosPallet?: Record<string, any>;
  isAddingPallet?: boolean;
  isRipping?: boolean;
  isConsuming?: boolean;
};

const initialState: TInitialState = {
  isLoading: false,
  cargas: [],
  error: "",
  isLoadingCarga: false,
};

const reducer = createSlice({
  name: "cargas",
  initialState,
  reducers: {
    requestCargas(state) {
      state.isLoading = true;
      state.cargas = [];
      state.error = "";
    },
    cargasSuccess(state, { payload }: PayloadAction<Record<string, any>[]>) {
      state.isLoading = false;
      state.cargas = payload;
    },
    cargasError(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    requestCarga(state, _: PayloadAction<string>) {
      state.isLoadingCarga = true;
      state.error = "";
      state.carga = undefined;
    },
    cargaSuccess(state, { payload }: PayloadAction<Record<string, any>>) {
      state.isLoadingCarga = false;
      state.carga = payload;
      state.itens = extractItens(payload);
    },
    cargaError(state, { payload }: PayloadAction<string>) {
      state.isLoadingCarga = false;
      state.error = payload;
    },
    carguistaRequest(state) {
      state.isCarguistaLoading = true;
      state.carguistas = [];
    },
    carguistaSuccess(state, { payload }: PayloadAction<Record<string, any>[]>) {
      state.isCarguistaLoading = false;
      state.carguistas = payload;
    },
    carguistaError(state, { payload }: PayloadAction<string>) {
      state.isCarguistaLoading = false;
      state.error = payload;
    },
    concluirRequest(
      state,
      _: PayloadAction<{
        UKEY: string;
        operador: string;
        navigate: NavigateFunction;
      }>
    ) {
      state.isFinishing = true;
    },
    concluirSuccess(state) {
      state.isFinishing = false;
    },
    concluirError(state, { payload }: PayloadAction<string>) {
      state.isFinishing = false;
      state.error = payload;
    },
    clearError(state) {
      state.error = "";
    },
    requestCheckPallet(
      state,
      _: PayloadAction<{
        codigoPallet: string;
        setQtd: (qtd: number) => void;
        itens: Record<string, any>;
      }>
    ) {
      state.isCheckingPallet = true;
      state.error = "";
      state.dadosPallet = undefined;
    },
    checkPalletSuccess(state, { payload }: PayloadAction<Record<string, any>>) {
      state.isCheckingPallet = false;
      state.dadosPallet = payload;
    },
    checkPalletError(state, { payload }: PayloadAction<string>) {
      state.isCheckingPallet = false;
      state.error = payload;
    },
    clearCheckPallet(state) {
      state.dadosPallet = undefined;
    },
    addPalletRequest(
      state,
      _: PayloadAction<{
        ukey: string;
        qtd: number;
        codigoPallet: string;
        navigate: NavigateFunction;
      }>
    ) {
      state.isAddingPallet = true;
    },
    addPalletSuccess(state) {
      state.isAddingPallet = false;
    },
    addPalletError(state, { payload }: PayloadAction<string>) {
      state.isAddingPallet = false;
      state.error = payload;
    },
    cancelPalletRequest(
      state,
      _: PayloadAction<{
        ukey: string;
        codigoPallet: string;
        navigate: NavigateFunction;
      }>
    ) {
      state.isAddingPallet = true;
      state.error = "";
    },
    cancelPalletSuccess(state) {
      state.isAddingPallet = false;
    },
    cancelPalletError(state, { payload }: PayloadAction<string>) {
      state.isAddingPallet = false;
      state.error = payload;
    },
    rippedRequest(
      state,
      _: PayloadAction<{ carga: string; item: string; qtd: number }>
    ) {
      state.isRipping = true;
      state.error = "";
    },
    rippedSuccess(state) {
      state.isRipping = false;
    },
    rippedError(state, { payload }: PayloadAction<string>) {
      state.isRipping = false;
      state.error = payload;
    },
    consumeRequest(
      state,
      _: PayloadAction<{
        codigoPallet: string;
        motivo: string;
        qtd: number;
        navigate: NavigateFunction;
      }>
    ) {
      state.isConsuming = true;
    },
    consumeSuccess(state) {
      state.isConsuming = false;
    },
    consumeError(state, { payload }: PayloadAction<string>) {
      state.isConsuming = false;
      state.error = payload;
    },
  },
});

export const cargasActions = reducer.actions;

export default reducer.reducer;
