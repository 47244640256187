import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  CircularProgress,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { faArrowLeft, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../app/mainReducer";
import { pickingActions } from "./reducer";

export const PickingReport: React.FC<{}> = () => {
  const isLoading = useSelector((state: RootState) => state.picking.isLoading);
  const error = useSelector((state: RootState) => state.picking.error);
  const itens = useSelector((state: RootState) => state.picking.itens);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(pickingActions.requestReport());
  }, [dispatch]);

  if (isLoading) {
    return (
      <Flex
        w="100vw"
        h="100vh"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress isIndeterminate={true} />
      </Flex>
    );
  }

  return (
    <Flex direction="column" w="100vw" h="100vh">
      <Box py="1rem" px="1rem" bgColor="blue.200">
        <Center>
          <IconButton
            colorScheme="grey"
            aria-label="Voltar"
            mr="0.4rem"
            onClick={() => navigate("/")}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faArrowLeft}
                title="Voltar"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Atualizar"
            mr="0.4rem"
            onClick={() => dispatch(pickingActions.requestReport())}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faSync}
                title="Atualizar"
              />
            }
          />
        </Center>
        {error && (
          <Alert status="error" minH="3rem">
            <AlertIcon />
            <AlertTitle>Erro!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <Center my="1rem">
          <Text fontSize="xl" as="b">
            Relatório do Picking
          </Text>
        </Center>
      </Box>
      {itens.map((item, i) => (
        <Box
          key={`${i}`}
          mb="0.5rem"
          px="1rem"
          py="1rem"
          borderBottomColor="#000"
          borderBottomWidth="1px"
        >
          <Flex direction="row" w="96%" justifyContent="space-between">
            <Flex direction="column">
              <Text as="b">{item.D04_001_C}</Text>
              <Text as="b">{item.D04_002_C}</Text>
              <Text>Endereço: {item.D04_118_C}</Text>
            </Flex>
            <Text as="b">
              {item.saldo} Itens/{item.pallets} Pallets
            </Text>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
};
