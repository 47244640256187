import { Button, ButtonGroup, InputProps, Text } from "@chakra-ui/react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const QtdInput: React.FC<
  InputProps & { value: number; onChange: (value: number) => void }
> = (props) => {
  return (
    <>
      <ButtonGroup isAttached variant="outline">
        <Button
          aria-label="Diminuir"
          onClick={() =>
            props.onChange(props.value - 10 > 0 ? props.value - 10 : 0)
          }
        >
          <FontAwesomeIcon icon={faMinus} /> 10
        </Button>
        <Button
          aria-label="Diminuir"
          onClick={() =>
            props.onChange(props.value - 1 > 0 ? props.value - 1 : 0)
          }
        >
          <FontAwesomeIcon icon={faMinus} /> 1
        </Button>
      </ButtonGroup>
      <Text fontSize="lg" mx="1rem">
        {props.value}
      </Text>
      <ButtonGroup isAttached variant="outline">
        <Button
          aria-label="Aumentar"
          onClick={() => props.onChange(props.value + 1)}
        >
          <FontAwesomeIcon icon={faPlus} /> 1
        </Button>
        <Button
          aria-label="Aumentar"
          onClick={() => props.onChange(props.value + 10)}
        >
          <FontAwesomeIcon icon={faPlus} /> 10
        </Button>
      </ButtonGroup>
    </>
  );
};
