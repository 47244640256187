import _ from "underscore";

export const extractItens = (carga: Record<string, any>) => {
  const j14s = carga.j14s;
  let itens: Record<string, any> = {};
  for (let i in j14s) {
    const j15s = j14s[i].j15s;
    for (let j in j15s) {
      if (j15s[j].j07?.J07_071_N === 1 || j15s[j].j07?.J07_071_N === 2) {
        // Pedido de bonificacao
        console.log("status j07_071_N", j15s[j].j07.J07_071_N);
        itens[j15s[j].j07.J07_001_C] = {
          d04: {
            UKEY: j15s[j].j07.UKEY,
            D04_001_C: j15s[j].j07.J07_001_C,
            D04_022_B: 0,
            D04_002_C: `Pedido de bonificação`,
            D04_074_N: 1,
            D04_118_C: "",
          },
          j07: true,
          qtd: 1,
          read: j15s[j].j07.J07_071_N === 2 ? 1 : 0,
        };
        continue;
      }
      const j08s = j15s[j].j07?.j08s;
      for (let k in j08s) {
        const j08 = j08s[k];
        // console.log(`tem k21 ou nao ${j08.d04.k21}`);
        if (j08.d04.k21) {
          if (itens[j08.d04.D04_001_C]) {
            itens[j08.d04.D04_001_C].qtd += j08.J08_003_N;
          } else {
            // console.log(j08.d04);
            itens[j08.d04.D04_001_C] = {
              d04: j08.d04,
              qtd: j08.J08_003_N,
              read: 0,
            };
          }
        }
      }
    }
  }

  // Computar a quantidade ja lida até o momento
  if (!!carga.k19 && !!carga.k19.k20s) {
    // console.log(`k20s ${JSON.stringify(this.state.carga.k19.k20s)}`);
    _.each(itens, (item) => {
      if (item.j07) {
        return;
      }
      item.read = carga.k19.k20s.reduce((_: any, k20: any) => {
        if (k20.D04_UKEY === item.d04.UKEY) return _ + k20.K20_001_N;
        return _;
      }, 0);
      item.k20s = carga.k19.k20s.filter(
        (k20: any) => k20.D04_UKEY === item.d04.UKEY
      );
    });
  }

  return itens;
};
